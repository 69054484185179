export * from './lib/breakpoints'
export { default as ThemeConfig } from './lib/index'
export {default as GlobalStyles} from './lib/globalStyles'
export * from './lib/overrides'
export * from './lib/palette'
export {default as palette} from './lib/palette'
//export * as palette from "./lib/palette"
export * from './lib/shadows'
export * from './lib/shape'
export * from './lib/typography'
export * from './lib/test'

