import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LandingPage from '../pages/LandingPage';

// guards
import { GuestGuard, AuthGuard } from '../guards';
// components
import { LoadingScreen } from '@blockchainfirst/ui-shared/components';

// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  console.log('Loadable pathname: ', pathname);
  const isDashboard = pathname.includes('/dashboard');
  console.log('includes dashboard: ', pathname.includes('/dashboard'));

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'examstart', element: <ExamStart /> },
        { path: 'documents', element: <Documents /> },
        { path: 'pdf', element: <PagePDF /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            // { path: 'cards', element: <UserCards /> },
            // { path: 'list', element: <UserList /> },
            // { path: 'new', element: <UserCreate /> },
            // { path: ':name/edit', element: <UserCreate /> },
            // { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'app',
          children: [
            { element: <Navigate to="/dashboard/app/four" replace /> },
            // { path: 'categories', element: <Categories /> },
            { path: 'questions', element: <Questions /> },
            { path: 'questionsandanswers', element: <QuestionsAndAnswer /> },
            { path: 'documents', element: <ManageDocuments /> },
            { path: 'addexamquestion', element: <AddExamQuestion /> },
            { path: 'question/new', element: <TestPage mode="new" /> },
            { path: 'question/edit/:id', element: <TestPage mode="edit" /> },
          ],
        },
      ],
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        // { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        //{ element: <LandingPage /> },
        { element: <Navigate to="/" replace /> },
        { path: '', element: <LandingPage /> },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'map', element: <MapPage /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

const GeneralApp = Loadable(lazy(() => import('../pages/Dashboard')));

const MapPage = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.MapPage,
    }))
  )
);

const About = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.About,
    }))
  )
);

const Contact = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.Contact,
    }))
  )
);

const Faqs = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.Faqs,
    }))
  )
);

const Login = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.Login,
    }))
  )
);

const Register = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.Register,
    }))
  )
);

const ResetPassword = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.ResetPassword,
    }))
  )
);

const VerifyCode = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.VerifyCode,
    }))
  )
);

const UserProfile = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.UserProfile,
    }))
  )
);

const ExamStart = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.ExamStart,
    }))
  )
);

// const Categories = Loadable(
//   lazy(() =>
//     import('../pages').then((module) => ({
//       default: module.Categories,
//     }))
//   )
// );

const Questions = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.Questions,
    }))
  )
);
const QuestionsAndAnswer = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.QuestionsAndAnswer,
    }))
  )
);

const Documents = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.PageDocuments,
    }))
  )
);

const ManageDocuments = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.PageMangeDocuments,
    }))
  )
);

const AddExamQuestion = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.AddExamQuestion,
    }))
  )
);

const TestPage = Loadable(
  lazy(() =>
    import('../pages').then((module) => ({
      default: module.TestPage,
    }))
  )
);

const PagePDF = Loadable(
  lazy(() =>
    import('@blockchainfirst/ui-shared/pages').then((module) => ({
      default: module.PagePDF,
    }))
  )
);

const NotFound = Loadable(
  lazy(() =>
    import('@blockchainfirst/ui-shared/pages').then((module) => ({
      default: module.Page404,
    }))
  )
);

const PageSendCryto = Loadable(
  lazy(() =>
    import('@blockchainfirst/ui-shared/pages').then((module) => ({
      default: module.PageSendCryto,
    }))
  )
);
