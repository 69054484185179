import { Icon } from '@iconify/react';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
import chevronRightFill from '@iconify/icons-eva/chevron-right-fill';
// material
import { useTheme, styled, alpha } from '@mui/material/styles';
import {
  Box,
  Grid,
  Card,
  Link,
  Stack,
  Button,
  Divider,
  Container,
  Typography,
} from '@mui/material';
//
import {
  varFadeIn,
  varFadeInUp,
  MotionInView,
  varFadeInDown,
} from '@blockchainfirst/ui-shared/components';

// ----------------------------------------------------------------------

const LICENSES = [
  'Short Tour (20 min)',
  'Mid Tour (30 min)',
  'Long tour (1 hour)',
];
const PRICES = ['130$', '170$', '260$'];
const PLANS = [...Array(3)].map((_, index) => ({
  license: LICENSES[index],
  price: PRICES[index],
  commons: [
    'Briefing and safety.',
    'Take-off from the Airport',
    'Beaches, jungle, from above',
    'Chance to see wild animals',
    'Crocodiles, birds, and more!',
  ],
  options: ['Flamingo, Conchal, Playa grande.', 'Potrero', 'Playas del Coco'],
  icons: [
    // '/static/home/ic_sketch.svg',
    // '/static/home/ic_figma.svg',
    // '/static/home/ic_js.svg',
    // '/static/home/ic_ts.svg',
  ],
}));

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(15),
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(15),
  },
}));

// ----------------------------------------------------------------------

type PlanCardProps = {
  plan: {
    license: string;
    price: string;
    commons: string[];
    options: string[];
    icons: string[];
  };
  cardIndex: number;
};

function PlanCard({ plan, cardIndex }: PlanCardProps) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const { license, price, commons, options, icons } = plan;

  return (
    <Card
      sx={{
        p: 5,
        boxShadow: (theme) =>
          `0px 48px 80px ${alpha(
            isLight ? theme.palette.grey[500] : theme.palette.common.black,
            0.12
          )}`,
        ...(cardIndex === 1 && {
          boxShadow: (theme) =>
            `0px 48px 80px ${alpha(
              isLight ? theme.palette.grey[500] : theme.palette.common.black,
              0.48
            )}`,
        }),
      }}
    >
      <Stack spacing={5}>
        <div>
          <Typography
            variant="overline"
            sx={{ mb: 1, color: 'text.disabled', display: 'block' }}
          >
            Variant
          </Typography>
          <Typography variant="h4">{license}</Typography>
        </div>
        <Typography
          sx={{ color: 'primary.main' }}
          // sx={{ mb: 1, color: 'text.red', display: 'block' }}
          variant="h2"
        >
          {price}
        </Typography>

        {/* {cardIndex === 0 ? (
          <Box component="img" src={icons[2]} sx={{ width: 40, height: 40 }} />
        ) : (
          <Stack direction="row" spacing={1}>
            {icons.map((icon) => (
              <Box
                key={icon}
                component="img"
                src={icon}
                sx={{ width: 40, height: 40 }}
              />
            ))}
          </Stack>
        )} */}

        <Stack spacing={2.5}>
          {commons.map((option) => (
            <Stack
              key={option}
              spacing={1.5}
              direction="row"
              alignItems="center"
            >
              <Box
                component={Icon}
                icon={checkmarkFill}
                sx={{ color: 'primary.main', width: 20, height: 20 }}
              />
              <Typography variant="body2">{option}</Typography>
            </Stack>
          ))}

          <Divider sx={{ borderStyle: 'dashed' }} />

          {options.map((option, optionIndex) => {
            const disabledLine =
              (cardIndex === 0 && optionIndex === 1) ||
              (cardIndex === 0 && optionIndex === 2) ||
              (cardIndex === 1 && optionIndex === 2) ||
              (cardIndex === 1 && optionIndex === 4);

            return (
              <Stack
                spacing={1.5}
                direction="row"
                alignItems="center"
                sx={{
                  ...(disabledLine && { color: 'text.disabled' }),
                }}
                key={option}
              >
                <Box
                  component={Icon}
                  icon={checkmarkFill}
                  sx={{
                    width: 20,
                    height: 20,
                    color: 'primary.main',
                    ...(disabledLine && { color: 'text.disabled' }),
                  }}
                />
                <Typography variant="body2">{option}</Typography>
              </Stack>
            );
          })}
        </Stack>

        {/* <Stack direction="row" justifyContent="flex-end">
          <Link
            color="text.secondary"
            underline="always"
            target="_blank"
            href="https://material-ui.com/store/license/#i-standard-license"
            sx={{
              typography: 'body2',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Learn more <Icon icon={chevronRightFill} width={20} height={20} />
          </Link>
        </Stack> */}

        <Button
          size="large"
          fullWidth
          variant={cardIndex === 1 ? 'contained' : 'outlined'}
          target="_blank"
          href=""
        >
          Book now
        </Button>
      </Stack>
    </Card>
  );
}

export default function LandingPricingPlans() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Box sx={{ mb: 10, textAlign: 'center' }}>
          <MotionInView variants={varFadeInUp}>
            <Typography
              component="p"
              variant="overline"
              sx={{ mb: 2, color: 'text.secondary' }}
            >
              Tours & Pricing
            </Typography>
          </MotionInView>
          <MotionInView variants={varFadeInDown}>
            <Typography variant="h2" sx={{ mb: 3 }}>
              The right Tour for your Budget and Schedule
            </Typography>
          </MotionInView>
          <MotionInView variants={varFadeInDown}>
            <Typography
              sx={{
                color: isLight ? 'text.secondary' : 'text.primary',
              }}
            >
              We offer local tours in Tamarindo and Samara and regional tours of
              the Nicoya Peninsula, Manuel Antonio, Peninsula Osa, the
              Volcanoes, Caribbean Coast, Panama.Do you need to go to a specific
              location? Ask us for a quotation and we'll do our best to
              accomodate you!
            </Typography>
          </MotionInView>
        </Box>

        <Grid container spacing={5}>
          {PLANS.map((plan, index) => (
            <Grid key={plan.license} item xs={12} md={4}>
              <MotionInView
                variants={index === 1 ? varFadeInDown : varFadeInUp}
              >
                <PlanCard plan={plan} cardIndex={index} />
              </MotionInView>
            </Grid>
          ))}
        </Grid>

        <MotionInView variants={varFadeIn}>
          <Box sx={{ p: 5, mt: 10, textAlign: 'center' }}>
            <MotionInView variants={varFadeInDown}>
              <Typography variant="h3">Still have questions?</Typography>
            </MotionInView>

            <MotionInView variants={varFadeInDown}>
              <Typography sx={{ mt: 3, mb: 5, color: 'text.secondary' }}>
                Please describe your case to receive the most accurate advice.
              </Typography>
            </MotionInView>

            <MotionInView variants={varFadeInUp}>
              <Button
                size="large"
                variant="contained"
                href="mailto:info@pura-flying.org?subject=[Feedback] from Customer"
              >
                Contact us
              </Button>
            </MotionInView>
          </Box>
        </MotionInView>
      </Container>
    </RootStyle>
  );
}
