import AxiosMockAdapter from 'axios-mock-adapter';
import {axios} from '@blockchainfirst/utils/functions';

// ----------------------------------------------------------------------

const axiosMockAdapter = new AxiosMockAdapter(axios, {
  delayResponse: 0
});

export default axiosMockAdapter;
