import React, { useState, useRef, Ref, forwardRef } from 'react';
import { formatEther } from '@ethersproject/units';
import { useEthers, useEtherBalance } from '@usedapp/core';
import { useCoingeckoPrice, useCoingeckoTokenPrice } from '@usedapp/coingecko';
// material
import {
  Container,
  Typography,
  Stack,
  Chip,
  ChipProps,
  Avatar,
  Tooltip,
  Button,
} from '@mui/material';
// hooks
import { useSettings } from '@blockchainfirst/hooks';
// components
import { alpha } from '@mui/material/styles';
// Icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
// Hooks
//import { useExchangeEthPrice } from 'eth-hooks/dapps/dex';
// Customised Components
import CustomizedSnackbar from '../CustomizedSnackbar';
import Spinner from '../Spinner';
import AlertDialog from '../AlertDialog';

const AccountChip = forwardRef<HTMLButtonElement, ChipProps>(
  ({ onClick, children, sx, ...other }, ref: Ref<any>) => {
    const { themeStretch } = useSettings();
    //const price = 0; // useExchangeEthPrice(NETWORKS.mumbai, provider);
    //const price = Number(useCoingeckoPrice('matic-network', 'usd'));
    const snackBarRef = useRef(null);
    const { account, activateBrowserWallet, error } = useEthers();

    const isConnected = account !== undefined;

    return (
      <>
        <Button onClick={() => activateBrowserWallet()} variant="contained">
          Connect
        </Button>
      </>
    );
  }
);

export default AccountChip;
