// material
import { styled } from '@mui/material/styles';
// components
import { Page } from '@blockchainfirst/ui-shared/components';
import {
  LandingHero,
  LandingMinimal,
  LandingDarkMode,
  LandingThemeColor,
  LandingPricingPlans,
  LandingAdvertisement,
  LandingCleanInterfaces,
  LandingHugePackElements,
  LandingCarousel,
  LandingWindy,
} from '../components/_external-pages/landing';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)({
  height: '100%',
});

const ContentStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function LandingPage() {
  return (
    <RootStyle title="Landing Page" id="move_top">
      <LandingHero />
      <ContentStyle>
        {/* <LandingMinimal /> */}
        {/* <LandingHugePackElements /> */}
        {/* <LandingDarkMode /> */}
        {/* <LandingThemeColor /> */}
        {/* <LandingCleanInterfaces /> */}
        <LandingPricingPlans />
        <LandingCarousel />
        <LandingWindy />
        {/* <LandingAdvertisement /> */}
      </ContentStyle>
    </RootStyle>
  );
}
