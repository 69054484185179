export const RPC_URLS = {
  rpc_url_mainnet: process.env.NX_CUSTOM_APP_RPC_URL_MAINNET,
  rpc_url_ropsten: process.env.NX_CUSTOM_APP_RPC_URL_ROPSTEN,
  rpc_url_kovan: process.env.NX_CUSTOM_APP_RPC_URL_KOVAN,
  rpc_url_rinkeby: process.env.NX_CUSTOM_APP_RPC_URL_RINKEBY,
  rpc_url_goerli: process.env.NX_CUSTOM_APP_RPC_URL_GOERLI,
  rpc_url_polygon: process.env.NX_CUSTOM_APP_RPC_URL_POLYGON,
  rpc_url_polygon_mumbai: process.env.NX_CUSTOM_APP_RPC_URL_MUMBAI,
};

export const InfuraId = process.env.NX_CUSTOM_APP_INFURAID
  ? process.env.NX_CUSTOM_APP_INFURAID
  : '';
export const AlchemyId = process.env.NX_CUSTOM_APP_ALCHEMYID
  ? process.env.NX_CUSTOM_APP_ALCHEMYID
  : '';

export const firebaseConfig = {
  apiKey: process.env.NX_CUSTOM_APP_FIREBASE_API_KEY,
  authDomain: process.env.NX_CUSTOM_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NX_CUSTOM_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.NX_CUSTOM_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NX_CUSTOM_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NX_CUSTOM_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NX_CUSTOM_APP_FIREBASE_APPID,
  measurementId: process.env.NX_CUSTOM_APP_FIREBASE_MEASUREMENT_ID,
};

export const cognitoConfig = {
  userPoolId: process.env.NX_CUSTOM_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.NX_CUSTOM_APP_AWS_COGNITO_CLIENT_ID,
};

export const auth0Config = {
  clientId: process.env.NX_CUSTOM_APP_AUTH0_CLIENT_ID,
  domain: process.env.NX_CUSTOM_APP_AUTH0_DOMAIN,
};

export const mapConfig = process.env.NX_CUSTOM_APP_MAP_MAPBOX;

export const googleAnalyticsConfig =
  process.env.NX_CUSTOM_APP_GA_MEASUREMENT_ID;
export const googleApiKey = process.env.NX_CUSTOM_APP_GOOGLE_APIKEY;
