// material
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------
// Logo made at https://www.ucraft.com/

export default function Logo({ sx }: BoxProps) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 50, height: 50, ...sx }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="0 0 455 549"
        height="100%"
        // width="455"
      >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g id="g26" transform="matrix(5.44,0,0,5.44,227.68,274.67)">
          <g id="g8" transform="matrix(1.45,0,0,1.45,1.74,0.04)">
            <path
              id="path6"
              d="m 61.536926,56.028686 c 0.11145,-0.161315 0.19159,-0.346069 0.19159,-0.557006 V 31.376953 c 0,-0.552246 -0.447266,-1 -1,-1 H 58.193359 V 22.11377 h -2 v 8.263183 h -2.535156 c -0.552734,0 -1,0.447754 -1,1 v 21.089661 c -0.485779,-0.04285 -0.974914,-0.07452 -1.47168,-0.07452 -3.223327,0 -6.225341,0.936889 -8.776367,2.530884 v -5.32483 h 2.535156 c 0.552735,0 1,-0.447753 1,-1 V 23.075195 c 0,-0.552246 -0.447265,-1 -1,-1 h -2.535156 v -5.44873 h -2 v 5.44873 H 37.875 c -0.552734,0 -1,0.447754 -1,1 v 25.52295 c 0,0.552246 0.447266,1 1,1 h 2.535156 v 6.707031 h 0.0827 c -3.624146,3.052673 -5.935242,7.617126 -5.935242,12.715332 0,9.169433 7.459961,16.628906 16.628906,16.628906 9.168946,0 16.628907,-7.459473 16.628907,-16.628906 0,-5.256409 -2.458557,-9.942444 -6.278504,-12.991822 z M 38.875,24.075195 h 5.070312 v 23.52295 H 38.875 Z m 15.783203,8.301758 h 5.070313 V 54.47168 h -0.507691 c -0.228149,-0.126465 -0.465698,-0.236328 -0.700256,-0.352234 -0.174133,-0.08569 -0.347107,-0.173401 -0.523987,-0.252808 -0.270142,-0.121887 -0.544617,-0.233642 -0.822144,-0.341247 -0.131897,-0.05078 -0.264831,-0.09857 -0.39801,-0.145936 -0.688476,-0.246826 -1.391479,-0.461731 -2.118225,-0.61676 z m -3.47168,51.272461 c -8.066406,0 -14.628906,-6.5625 -14.628906,-14.628906 0,-8.065918 6.5625,-14.628418 14.628906,-14.628418 0.790284,0 1.560364,0.0805 2.317017,0.201721 0.426086,0.06805 0.846252,0.155518 1.260742,0.259216 0.02857,0.0072 0.05695,0.01465 0.08545,0.02203 0.387513,0.09943 0.769226,0.215393 1.145386,0.345703 0.06525,0.02283 0.129456,0.04755 0.194275,0.07123 0.344177,0.124756 0.683838,0.260864 1.017334,0.411438 0.09015,0.04096 0.177795,0.08606 0.267029,0.128784 0.311279,0.148316 0.61969,0.303467 0.920532,0.474366 4.424744,2.518493 7.421143,7.269104 7.421143,12.713928 0,8.066406 -6.5625,14.628906 -14.628907,14.628906 z"
              transform="translate(-51.19,-51.14)"
              fill={PRIMARY_MAIN}
              fillRule="evenodd"
              stroke="none"
              strokeWidth="1"
            />
          </g>
          <g id="g12" transform="matrix(1.45,0,0,1.45,1.73,25.92)">
            <path
              id="path10"
              d="m 51.186523,57.149414 c -6.545898,0 -11.871093,5.325195 -11.871093,11.871094 0,6.545898 5.325195,11.871582 11.871093,11.871582 6.545899,0 11.871094,-5.325684 11.871094,-11.871582 0,-6.545899 -5.325195,-11.871094 -11.871094,-11.871094 z m 0,22.742676 c -5.99414,0 -10.871093,-4.876953 -10.871093,-10.871582 0,-5.994141 4.876953,-10.871094 10.871093,-10.871094 5.994141,0 10.871094,4.876953 10.871094,10.871094 0,5.994629 -4.876953,10.871582 -10.871094,10.871582 z"
              transform="translate(-51.19,-69.02)"
              fill={PRIMARY_MAIN}
              fillRule="evenodd"
              stroke="none"
              strokeWidth="1"
            />
          </g>
          <g id="g16" transform="matrix(1.45,0,0,1.45,-34.92,-5.45)">
            <path
              id="path14"
              d="m 29.426758,30.376465 h -2.535157 v -6.313477 h -2 v 6.313477 h -2.534179 c -0.552735,0 -1,0.447754 -1,1 v 31.551758 c 0,0.552246 0.447265,1 1,1 h 2.534179 v 6.725097 h 2 v -6.725097 h 2.535157 c 0.552734,0 1,-0.447754 1,-1 V 31.376465 c 0,-0.552246 -0.447266,-1 -1,-1 z m -1,31.551758 H 23.357422 V 32.376465 h 5.069336 z"
              transform="translate(-25.89,-47.36)"
              fill={PRIMARY_MAIN}
              fillRule="evenodd"
              stroke="none"
              strokeWidth="1"
            />
          </g>
          <g id="g20" transform="matrix(1.45,0,0,1.45,34.93,-20.59)">
            <path
              id="path18"
              d="m 77.642578,30.376465 h -2.53418 v -13.75 h -2 v 13.75 h -2.535156 c -0.552734,0 -1,0.447754 -1,1 v 15.775879 c 0,0.552246 0.447266,1 1,1 h 2.535156 v 9.04248 h 2 v -9.04248 h 2.53418 c 0.552734,0 1,-0.447754 1,-1 V 31.376465 c 0,-0.552246 -0.447266,-1 -1,-1 z m -1,15.775879 H 71.573242 V 32.376465 h 5.069336 z"
              transform="translate(-74.11,-36.91)"
              fill={PRIMARY_MAIN}
              fillRule="evenodd"
              stroke="none"
              strokeWidth="1"
            />
          </g>
          <g id="g24" transform="matrix(1.45,0,0,1.45,1.73,25.92)">
            <path
              id="path22"
              d="m 54.877808,73.308289 c -0.321595,0.662963 -0.497559,0.745788 -1.158875,0.753173 v -0.0032 h -0.02576 -4.07489 -0.0257 v 0.0032 c -0.661316,-0.0074 -0.83728,-0.09021 -1.158874,-0.753173 L 46.98584,70.340271 c -0.20459,-0.412048 -0.35791,-0.703552 -0.35791,-1.184082 0,-0.531799 0.136108,-0.840515 0.35791,-1.303833 l 1.4823,-2.98523 c 0.315124,-0.635131 0.440857,-0.71582 1.12445,-0.719543 v 0.004 h 0.0257 4.07489 0.02576 v -0.004 c 0.683594,0.0037 0.809326,0.08441 1.124451,0.719543 l 0.617004,1.242615 h 1.885864 L 56.342903,64.078308 C 55.763679,62.894165 55.031135,62.431335 53.71894,62.431335 h -0.02576 -1.158142 v -2.34851 h -0.0257 -1.707214 -0.02564 v 2.34851 h -1.158203 -0.0257 c -1.312195,0 -2.044739,0.46283 -2.624023,1.646973 l -1.567566,3.173828 c -0.340637,0.685913 -0.476746,1.149231 -0.476746,1.904053 0,0.669128 0.170105,1.218078 0.459961,1.801514 l 1.567566,3.191101 c 0.545227,1.115173 1.277771,1.62976 2.555542,1.62976 h 0.08527 0.0257 1.158203 v 2.180115 h 0.02564 1.707214 0.0257 v -2.180115 h 1.158142 0.02576 0.08527 c 1.277771,0 2.010315,-0.514587 2.555542,-1.62976 l 1.089111,-2.217041 h -1.899536 z"
              transform="translate(-51.19,-69.02)"
              fill={PRIMARY_MAIN}
              fillRule="evenodd"
              stroke="none"
              strokeWidth="1"
            />
          </g>
        </g>
      </svg>
    </Box>
  );
}
