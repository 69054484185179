import { useContext } from 'react';
//import { AuthContext } from '@blockchainfirst/ui-shared/contexts';
// import { AuthContext } from '../contexts/AwsCognitoContext';
// import { AuthContext } from '../contexts/Auth0Context';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
//import { AuthContext } from '@blockchainfirst/ui-shared/contexts';
import { AuthContext } from '../contexts/FirebaseContext';

// ----------------------------------------------------------------------

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};
