import React, { ReactNode } from 'react';
// material
import { Breakpoint, Theme, useMediaQuery } from '@mui/material';

// ----------------------------------------------------------------------

type MHiddenProps = {
  children: ReactNode;
  width:
    | 'xsDown'
    | 'smDown'
    | 'mdDown'
    | 'lgDown'
    | 'xlDown'
    | 'xsUp'
    | 'smUp'
    | 'mdUp'
    | 'lgUp'
    | 'xlUp';
};

export default function MHidden({ width, children }: MHiddenProps) {
  // tslint:ignore
  const breakpoint = width.substring(0, 2) as Breakpoint;
  const hiddenUp = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up(breakpoint)
  );
  const hiddenDown = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down(breakpoint)
  );

  if (width.includes('Down')) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <React.Fragment>{hiddenDown ? null : children}</React.Fragment>;
  }

  if (width.includes('Up')) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <React.Fragment>{hiddenUp ? null : children}</React.Fragment>;
  }

  return <React.Fragment>null</React.Fragment>;
}
