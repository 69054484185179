// @ts-ignore
import contractData from './smart-contracts.json';

export type CONTRACTDATA_ITEM = {
  name: string;
  address: string;
  chaindata: {
    networkname: string;
    chainId: number;
  };
  abi: any[];
};

export const getContractDataByName = (name: string) => {
  return contractData.find((data: any) => {
    return data.name === name;
  }) as CONTRACTDATA_ITEM;
};

export const getContractData = (chainId: number) => {
  return contractData.find((data: any) => {
    return data.chaindata.chainId === chainId;
  });
  //const contractData: CONTRACTDATA_ITEM[] = [];
  //   for (let i = 0; i <= contractData.length; i++) {
  //     console.log('contractData: ', contractData[i]);
  //   }
  //   for (const n in contractData) {
  //     if (contractData[n].chaindata.chainId === chainId) {
  //       console.log('contractData: ', contractData[n]);
  //       // @ts-ignore
  //       return contractData[n];
  //     }
  //   }
};
