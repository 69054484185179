export { default as LandingHero } from './LandingHero';
export { default as LandingMinimal } from './LandingMinimal';
export { default as LandingDarkMode } from './LandingDarkMode';
export { default as LandingThemeColor } from './LandingThemeColor';
export { default as LandingPricingPlans } from './LandingPricingPlans';
export { default as LandingAdvertisement } from './LandingAdvertisement';
export { default as LandingCleanInterfaces } from './LandingCleanInterfaces';
export { default as LandingHugePackElements } from './LandingHugePackElements';
export { default as LandingCarousel } from './LandingCarousel';
export { default as LandingWindy } from './LandingWindy';

export { default as Map } from './map/map';
//export { default as SimpleExample } from './map/simple';

export * from './pricing';
export * from './about';
export * from './contact';
export * from './faqs';
export * from './payment';
