import { useRef, useState } from 'react';
// material
import {
  Avatar,
  Button,
  Box,
  Divider,
  Typography,
  Grid,
  Stack,
  Chip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { purple } from '@mui/material/colors';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Tooltip from '@mui/material/Tooltip';
import { Link as RouterLink } from 'react-router-dom';
import { NETWORK } from '@blockchainfirst/web3-utils';

// components
import {
  MenuPopover,
  CustomizedSnackbar,
  AccountChip,
} from '@blockchainfirst/ui-shared/components';
import { useEthers, useBlockMeta, useBlockNumber } from '@usedapp/core';

import Blockies from 'react-blockies';
//import { useGasPrice } from 'eth-hooks';

const useStyles = makeStyles((theme: any) => ({
  gasGauge: {
    marginTop: theme.spacing(1),
  },
  blockNumber: {
    marginRight: theme.spacing(0.5),
  },
  gasStationIcon: {
    color: purple[500],
  },
}));

export default function Wallet() {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const snackBarRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { account, deactivate, error } = useEthers();
  const address: string =
    account === undefined || account === null ? '' : account;
  const isConnected = account !== undefined;

  const blockNumber = useBlockNumber();
  const { chainId } = useEthers();
  const { timestamp, difficulty } = useBlockMeta();

  const chaindata: any = chainId ? NETWORK(chainId) : null;

  //const gasPrice = useGasPrice(chaindata, 'fast');

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const GetAddress = () => {
    const address: string =
      account === undefined || account === null ? '' : account;
    const displayAddress = `${address.substring(0, 11)}...${address.substring(
      address.length - 11
    )}`;

    function openOnEtherscanLink() {
      const blockExplorer: any =
        chaindata && chaindata.blockExplorer ? chaindata.blockExplorer : null;
      console.log('blockExplorer: ', blockExplorer);
      const link = `${
        blockExplorer || 'https://etherscan.io/'
      }${'address/'}${address}`;
      window.open(link, '_blank');
    }

    function copToClipboard() {
      navigator.clipboard.writeText(address);
      // eslint-disable-next-line
      // @ts-ignore
      snackBarRef.current.showSnackBar();
    }
    return (
      <>
        {/* <CustomizedSnackbar ref={snackBarRef} /> */}
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography
              sx={{ fontSize: '15px !important' }}
              variant="h6"
              component="h6"
              noWrap
            >
              {displayAddress}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Copy to clipboard" placement="bottom">
              <ContentCopyIcon
                onClick={copToClipboard}
                sx={{
                  marginTop: '5px !important',
                  fontSize: '15px !important',
                }}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip title="Show on Block Explorer" placement="bottom">
              <LaunchIcon
                onClick={() => {
                  openOnEtherscanLink();
                }}
                sx={{
                  marginTop: '5px !important',
                  fontSize: '15px !important',
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
        {/* <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {chainId}
        </Typography> */}
      </>
    );
  };

  const GetNetwork = () => {
    const chainname: string = chaindata && chaindata.name ? chaindata.name : '';
    const chaincolor: string =
      chaindata && chaindata.color ? chaindata.color : '#00e676';
    return (
      <>
        <Typography variant="subtitle1" noWrap>
          Network
        </Typography>
        <Stack direction="row" spacing={0}>
          <div className={classes.gasGauge}>
            <Chip
              clickable
              className={classes.blockNumber}
              variant="outlined"
              icon={
                <FiberManualRecordIcon
                  sx={{
                    fontSize: '14px !important',
                    color: `${chaincolor} !important`,
                  }}
                />
              }
              label={
                (chainname ? chainname.toUpperCase() : 'UNKNOWN') +
                ` (${chainId})`
              }
            />
            <Chip
              clickable
              className={classes.blockNumber}
              variant="outlined"
              icon={<AccountTreeIcon />}
              label={blockNumber}
            />
            {/* <Chip
              clickable
              variant="outlined"
              onClick={() => {
                window.open('https://ethgasstation.info/');
              }}
              deleteIcon={<LocalGasStationIcon />}
              icon={
                <LocalGasStationIcon
                  sx={{
                    color: `${purple[500]} !important`,
                  }}
                />
              }
              label={
                typeof gasPrice === 'undefined'
                  ? 0
                  : parseInt(gasPrice.toString(), 10) / 10 ** 9 + ' Gwei'
              }
            /> */}
          </div>
        </Stack>
      </>
    );
  };

  return (
    <>
      <AccountChip onClick={handleOpen} ref={anchorRef} />
      {isConnected && !error && (
        <MenuPopover
          open={open}
          onClose={handleClose}
          anchorEl={anchorRef.current}
          sx={{ width: 370 }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography variant="subtitle1" noWrap>
                  Account
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {(isConnected || error) && (
                  <Button
                    onClick={() => {
                      deactivate();
                      setOpen(false);
                    }}
                    size="small"
                    variant="outlined"
                  >
                    Disconnect
                  </Button>
                )}
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ my: 1 }} />

          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <Avatar>
                  <Blockies seed={address} size={15} scale={3} />
                </Avatar>
              </Grid>
              <Grid item xs={10}>
                <GetAddress />
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ my: 1 }} />

          <Box sx={{ p: 1, pt: 1.5 }}>
            <Stack direction="row" spacing={1}>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                component={RouterLink}
                to="/dashboard/buycrypto"
                variant="contained"
                startIcon={<AddIcon />}
              >
                Buy Crypto
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                component={RouterLink}
                to="/dashboard/sendcrypto"
                variant="contained"
                endIcon={<SendIcon />}
              >
                Send
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                component={RouterLink}
                to="/dashboard/sendcrypto"
                variant="contained"
                endIcon={<QrCode2Icon />}
              >
                Receive
              </Button>
            </Stack>
          </Box>

          <Divider sx={{ my: 1 }} />

          <Box sx={{ p: 2, pt: 1.5 }}>
            <GetNetwork />
          </Box>
        </MenuPopover>
      )}
    </>
  );
}
