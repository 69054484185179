// material
import { visuallyHidden } from '@mui/utils';
import {
  Box,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Avatar,
  Stack,
} from '@mui/material';

// ----------------------------------------------------------------------

type ListHeadProps = {
  order: 'asc' | 'desc';
  orderBy: string;
  rowCount: number;
  headLabel: any[];
  numSelected: number;
  role: string;
  onRequestSort: (id: string) => void;
  onSelectAllClick: (checked: boolean) => void;
};

export default function ListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  role,
  onRequestSort,
  onSelectAllClick,
}: ListHeadProps) {
  return (
    <TableHead>
      <TableRow>
        {role === 'admin' ? (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSelectAllClick(event.target.checked)
              }
            />
          </TableCell>
        ) : null}

        {headLabel.map((headCell) =>
          headCell.role === role || role === 'admin' ? (
            <TableCell
              key={headCell.id}
              //align={headCell.alignRight ? 'right' : 'left'}
              align={headCell.align ? headCell.align : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onRequestSort(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
}
