import * as Yup from 'yup';
import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { styled } from '@mui/material/styles';

// material
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  FormHelperText,
  FormControlLabel,
} from '@mui/material';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
// firebase
import {
  ref,
  getStorage,
  getDownloadURL,
  uploadBytesResumable,
} from 'firebase/storage';
import {
  addRecord,
  updateRecord,
  deleteRecord,
  useRecords,
  getRecord,
} from '@blockchainfirst/utils/database';
import { storage } from '@blockchainfirst/ui-shared/contexts';

// hooks
import { useSettings } from '@blockchainfirst/hooks';

// utils
// routes
// @types
import { DocumentManager } from '@blockchainfirst/types';
//
import Label from '../../Label';
import { UploadAvatar, UploadSingleFile } from '../../upload';

import {
  getFileType,
  getFileName,
  getFileFullName,
  getFileFormat,
  getFileThumb,
} from '@blockchainfirst/utils/functions';

// ----------------------------------------------------------------------

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

type DocumentNewFormProps = {
  isEdit: boolean;
  currentDocument?: DocumentManager;
  user: any;
};

export default function DocumentNewForm({
  isEdit,
  currentDocument,
  user,
}: DocumentNewFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  //const { user } = useAuth();
  const [progress, setProgress] = useState(0);
  const [filetype, setFileType] = useState('');
  const [preview, setFilePreview] = useState('');

  const COLLECTION = 'documents';
  const documents = useRecords(COLLECTION);
  console.log('documents: ', documents);

  const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  }));

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    //url: Yup.string().required('Images is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentDocument?.name || '',
      url: currentDocument?.url || '',
      filetype: currentDocument?.filetype || '',
      filename: currentDocument?.filename || '',
      isPublic: currentDocument?.isPublic || false,
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        console.log('onSubmit: ', values);
        const fileUrl = await saveFile(values);
        console.log('saveFile fileUrl: ', fileUrl);
        console.log('onSubmit: ', values);
        const uid = user?.id as string | null;
        if (uid) {
          const docRefId = await addRecord({ ...values }, COLLECTION);
          console.log('Document reference: : ', docRefId);
        }
        resetForm();
        setProgress(0);
        setSubmitting(false);
        enqueueSnackbar(!isEdit ? 'Create success' : 'Update success', {
          variant: 'success',
        });
        // navigate(PATH_DASHBOARD.user.list);
      } catch (error: any) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        console.log('url: ', file);
        // setFieldValue('url', {
        //   file,
        //   preview: URL.createObjectURL(file),
        // });
        setFieldValue('url', file);
        setFilePreview(URL.createObjectURL(file));
        setFieldValue('name', getFileName(file.path));
        setFieldValue('filetype', getFileFormat(file.path));
        setFieldValue('filename', file.name);
        setFileType(getFileFormat(file.path));
      }
    },
    [setFieldValue]
  );

  const saveFile = (document: DocumentManager) => {
    console.log('saveDocument: ', document);
    return new Promise<string | null>((resolve, reject) => {
      if (document.url) {
        console.log('url: ', document.url);
        console.log('user: ', user);
        const sotrageRef = ref(storage, `documents/${document.url.name}`);
        const uploadTask = uploadBytesResumable(sotrageRef, document.url);
        console.log('sotrageRef: ', sotrageRef);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const prog = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(prog);
          },
          (error) => {
            console.log(error);
            reject(error.message);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then(
              async (downloadURL) => {
                console.log('File available at', downloadURL);
                document.url = downloadURL;
                resolve(downloadURL);
              }
            );
          }
        );
      } else {
        resolve(null);
      }
    });
  };

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3 }}>
              <Box sx={{ mb: 5 }}>
                <div>
                  <LabelStyle>Add a Document, Image or Video</LabelStyle>
                  <UploadSingleFile
                    //maxSize={3145728}
                    maxSize={314572800}
                    accept="application/pdf,.pdf, video/*, image/*, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    file={values.url}
                    preview={preview}
                    onDrop={handleDrop}
                    error={Boolean(touched.url && errors.url)}
                    // caption={
                    //   <Typography
                    //     variant="caption"
                    //     sx={{
                    //       mt: 2,
                    //       mx: 'auto',
                    //       display: 'block',
                    //       textAlign: 'center',
                    //       color: 'text.secondary',
                    //     }}
                    //   >
                    //     Allowed *.jpeg, *.jpg, *.png, *.gif
                    //     <br /> max size of {fData(3145728)}
                    //   </Typography>
                    // }
                  />
                  {touched.url && errors.url && (
                    <FormHelperText error sx={{ px: 2 }}>
                      {touched.url && errors.url}
                    </FormHelperText>
                  )}
                </div>
              </Box>

              {isEdit && (
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Switch
                      onChange={(event) =>
                        setFieldValue(
                          'isPublic',
                          event.target.checked ? true : false
                        )
                      }
                      checked={values.isPublic !== false}
                    />
                  }
                  label={
                    <>
                      <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                        Public
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary' }}
                      >
                        File is visible for Users ?
                      </Typography>
                    </>
                  }
                  sx={{
                    mx: 0,
                    mb: 3,
                    width: 1,
                    justifyContent: 'space-between',
                  }}
                />
              )}
              {progress > 0 ? <LinearProgressWithLabel value={progress} /> : ''}
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 3, sm: 2 }}
                >
                  <TextField
                    fullWidth
                    label="Document Name"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Stack>

                <Box
                  sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}
                >
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {!isEdit ? 'Create User' : 'Save Changes'}
                  </LoadingButton>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
