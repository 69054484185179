// theme
import { ThemeConfig } from '@blockchainfirst/theme';
import { GlobalStyles } from '@blockchainfirst/theme';
// Hooks
import { useAuth } from './hooks/useAuth';

// components
import { Settings } from '@blockchainfirst/ui-shared/components';
import { RtlLayout } from '@blockchainfirst/ui-shared/components';
import { ScrollToTop } from '@blockchainfirst/ui-shared/components';
import {
  LoadingScreen,
  ProgressBarStyle,
} from '@blockchainfirst/ui-shared/components';
import { ThemePrimaryColor } from '@blockchainfirst/ui-shared/components';
import { NotistackProvider } from '@blockchainfirst/ui-shared/components';
import { DialogProvider } from '@blockchainfirst/ui-shared/components';
//import { Web3ReactProvider } from '@web3-react/core';
//import { getLibrary } from '@blockchainfirst/web3-utils';
import Router from './routes';

function App() {
  const { isInitialized } = useAuth();

  return (
    // <Web3ReactProvider getLibrary={getLibrary}>
    <ThemeConfig>
      <ThemePrimaryColor>
        <DialogProvider>
          <NotistackProvider>
            <RtlLayout>
              <GlobalStyles />
              <ProgressBarStyle />
              <Settings />
              <ScrollToTop />
              {/* {component} */}
              {/* <Router /> */}
              {isInitialized ? <Router /> : <LoadingScreen />}
            </RtlLayout>
          </NotistackProvider>
        </DialogProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
    // </Web3ReactProvider>
  );
}

export default App;
