export enum QuestionCategory {
  ALL = 'all',
  AERONAUTIC = 'aeronautic',
}

//const categoryies: QuestionCategory[] = [
//   {
//     id: 1,
//     category: 'all',
//     name_english: 'All Chapters (1-7)',
//     name_german: 'All Chapters (1-7)',
//     name_espanol: 'All Chapters (1-7)',
//   },
//   {
//     id: 2,
//     category: 'aeronautic',
//     name_english: 'Chapter 1 - Aerodynamics',
//     name_german: 'Chapter 1 -Aerodynamics',
//     name_espanol: 'Chapter 1 -Aerodynamics',
//   },
//   {
//     id: 3,
//     category: 'components_of_ultralight',
//     name_english: 'Chapter 2 - Components of the Ultralight',
//     name_german: 'Chapter 2 - Components of the Ultralight',
//     name_espanol: 'Chapter 2 - Components of the Ultralight',
//   },
//   {
//     id: 4,
//     category: 'definitions',
//     name_english: 'Chapter 3 - Definitions',
//     name_german: 'Chapter 3 - Definitions',
//     name_espanol: 'Chapter 3 - Definitions',
//   },
//   {
//     id: 4,
//     category: 'meteorology',
//     name_english: 'Chapter 4 - Meteorology',
//     name_german: 'Chapter 4 - Meteorology',
//     name_espanol: 'Chapter 4 - Meteorology',
//   },
//   {
//     id: 5,
//     category: 'regulations',
//     name_english: 'Chapter 5 Regulations',
//     name_german: 'Chapter 5 Regulations',
//     name_espanol: 'Chapter 5 Regulations',
//   },
//   {
//     id: 6,
//     category: 'general_rules_of_the_air',
//     name_english: 'Chapter 6 General Rules of the Air',
//     name_german: 'Chapter 6 General Rules of the Air',
//     name_espanol: 'Chapter 6 General Rules of the Air',
//   },
//   {
//     id: 7,
//     category: 'flying_technique',
//     name_english: 'Chapter 7 - Flying technique',
//     name_german: 'Chapter 7 - Flying technique',
//     name_espanol: 'Chapter 7 - Flying technique',
//   },
// ];

export enum QuestionType {
  ALL = 'all',
  MULTIPLE = 'multiple',
  TRU_FALSE = 'boolean',
}

export enum QuestionDifficulty {
  ALL = 'all',
  EASY = 'easy',
  MEDIUM = 'medium',
  HARD = 'hard',
}

export type Questions = {
  english: string;
  espanol: string;
  german: string;
};

export type QuestionState = {
  id?: string;
  category: string;
  type: QuestionType;
  difficulty: string;
  question: {
    english: string;
    espanol: string;
    german: string;
  };
  correct_answer: {
    english: string;
    espanol: string;
    german: string;
  };
  incorrect_answers: {
    english: string[];
    espanol: string[];
    german: string[];
  };
  afterSubmit?: string;
  checkbox_english?: string | undefined;
  checkbox_espanol?: string | undefined;
  checkbox_german?: string | undefined;
};

export interface DialogEditMode {
  open: boolean;
  mode: EditMode;
}

export enum EditMode {
  NEW = 'new',
  EDIT = 'edit',
}
