import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import flashFill from '@iconify/icons-eva/shopping-cart-outline';

import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Button, Box, Link, Container, Typography, Stack } from '@mui/material';
// routes
//TODO: still to implement
//import { PATH_DASHBOARD } from '../../../routes/paths';
//
import {
  varFadeIn,
  varFadeInUp,
  varWrapEnter,
  varFadeInRight,
} from '@blockchainfirst/ui-shared/components';

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  //objectFit: 'cover',
  //backgroundColor: theme.palette.grey[100],
  // backgroundImage: 'url("/static/home/GyroFlightSunset.png")',
  // backgroundRepeat: 'no-repeat',
  // backgroundSize: 'cover',
  // backgroundPositionY: '-250px',
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
  },
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(
  ({ theme }) => ({
    zIndex: 10,
    maxWidth: 520,
    margin: 'auto',
    textAlign: 'center',
    position: 'relative',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
      margin: 'unset',
      textAlign: 'left',
    },
  })
);

const HeroOverlayStyle = styled(motion.img)({
  zIndex: 9,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
  //backgroundColor: 'red',
  opacity: 0,
});

const HeroImgStyle = styled(motion.img)(({ theme }) => ({
  // top: 0,
  // right: 0,
  // bottom: 0,
  // zIndex: 8,
  // width: '100%',
  // margin: 'auto',
  // position: 'absolute',
  // borderRadius: '16px',
  // [theme.breakpoints.up('lg')]: {
  //   right: '8%',
  //   width: 'auto',
  //   height: '48vh',
  // },
}));

const HeroVideoStyle = styled(motion.video)(({ theme }) => ({
  zIndex: 9,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
}));

// ----------------------------------------------------------------------

export default function LandingHero() {
  return (
    <>
      <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
        {/* <HeroOverlayStyle
          alt="overlay"
          src="/static/overlay.svg"
          variants={varFadeIn}
        /> */}

        {/* src="/static/home/GyroFlightMovie1.MOV" */}

        <HeroVideoStyle
          autoPlay
          muted
          loop
          //poster="/static/home/GyroFlightSunset.png"
          variants={varFadeInUp}
          src="/static/home/GyroFlightMovie1.MOV"
          // type="video/mp4"
        ></HeroVideoStyle>

        <Container maxWidth="lg">
          <ContentStyle>
            <motion.div variants={varFadeInRight}>
              <Typography variant="h1" sx={{ color: 'common.white' }}>
                Welcome to Pura Flying!
                <br />
                <Typography
                  component="span"
                  variant="h1"
                  sx={{ color: 'primary.main' }}
                >
                  Gyrocopter Tours in Costa Rica & near by Countries
                </Typography>
              </Typography>
            </motion.div>

            <motion.div variants={varFadeInRight}>
              <Typography
                component="span"
                variant="h5"
                sx={{ color: 'common.white' }}
              >
                Fly over the amazing Costa Rican coast and jungle on an
                adrenaline packed flight! We offer roundtrips and flight
                instruction. Operated by Fly With Us!
              </Typography>
            </motion.div>

            <Stack
              component={motion.div}
              variants={varFadeInRight}
              direction="row"
              spacing={1}
              justifyContent={{ xs: 'center', md: 'flex-start' }}
            >
              {/* <img
                alt="sketch icon"
                src="/static/home/ic_sketch_small.svg"
                width={20}
                height={20}
              /> */}
              {/* <Link
                underline="always"
                href="https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0"
                target="_blank"
                color="common.white"
                sx={{ typography: 'body2' }}
              >
                Preview in Sketch Cloud
              </Link> */}
            </Stack>

            <motion.div variants={varFadeInRight}>
              <Button
                size="large"
                variant="contained"
                component={RouterLink}
                // to={PATH_DASHBOARD.root}
                to={'/'}
                startIcon={
                  <Icon icon={'mdi:airplane-clock'} width={20} height={20} />
                }
              >
                Book a flight now
              </Button>
            </motion.div>

            {/* <Stack
              direction="row"
              spacing={1.5}
              justifyContent={{ xs: 'center', md: 'flex-start' }}
            >
              <motion.img
                variants={varFadeInRight}
                src="/static/home/ic_sketch.svg"
              />
              <motion.img
                variants={varFadeInRight}
                src="/static/home/ic_figma.svg"
              />
              <motion.img
                variants={varFadeInRight}
                src="/static/home/ic_material.svg"
              />
              <motion.img
                variants={varFadeInRight}
                src="/static/home/ic_react.svg"
              />
              <motion.img
                variants={varFadeInRight}
                src="/static/home/ic_js.svg"
              />
              <motion.img
                variants={varFadeInRight}
                src="/static/home/ic_ts.svg"
              />
            </Stack> */}
          </ContentStyle>
        </Container>
      </RootStyle>
      <Box sx={{ height: { md: '100vh' } }} />
    </>
  );
}
