import React, { useState, useEffect, Ref } from 'react';
import { useEthers, useEtherBalance } from '@usedapp/core';
import { useCoingeckoPrice, useCoingeckoTokenPrice } from '@usedapp/coingecko';
import { ethers } from 'ethers';
import { formatEther } from '@ethersproject/units';
import { NETWORK } from '@blockchainfirst/web3-utils';

// Hooks
import { useExchangeEthPrice } from 'eth-hooks/dapps/dex';
import {
  Link,
  Stack,
  TextField,
  InputAdornment,
  Box,
  Grid,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  paddingRight: theme.spacing(0),
  textAlign: 'right',
  color: theme.palette.text.secondary,
  backgroundColor: 'transparent',
}));

export interface BalanceHeaderFieldProps {
  children?: React.ReactNode;
  formik: any;
}

export default function AmountsFormFields(
  props: BalanceHeaderFieldProps,
  ref: Ref<any>
) {
  const { children, formik, ...other } = props;
  const {
    errors,
    touched,
    values,
    handleChange,
    isSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  const price = Number(useCoingeckoPrice('matic-network', 'usd'));
  //console.log('useCoingeckoPrice: ', price);
  const { chainId } = useEthers();
  const chaindata: any = chainId ? NETWORK(chainId) : null;
  //onsole.log('chaindata: ', chaindata);
  const { account, error } = useEthers();
  const address: string =
    account === undefined || account === null ? '' : account;
  const isConnected = account !== undefined;
  const balance = useEtherBalance(account);
  const [etherBalance, setEtherBalance] = useState<number>(0);
  const [fiatBalance, setFiatBalance] = useState<number>(0);

  useEffect(() => {
    //console.log('useEffect');
    if (balance && isConnected) {
      const _balance = Number(parseFloat(formatEther(balance)).toPrecision(5));
      setEtherBalance(_balance);
      setFiatBalance(etherBalance * price);
    }
  }, [balance, isConnected]);

  const setMaxAmount = (event: React.SyntheticEvent) => {
    event.preventDefault();
    console.log('setMaxAmount');
    formik.setFieldValue('amountUSD', fiatBalance);
    formik.setFieldValue('amountETH', etherBalance);
  };

  const onChangeAmountETH = (e: any) => {
    handleChange(e);
    const { value } = e.target;
    const amountFIAT = (parseFloat(value) * price).toFixed(2);
    formik.setFieldValue('amountUSD', amountFIAT);
  };

  const onChangeAmountUSD = (e: any) => {
    handleChange(e);
    const { value } = e.target;
    const amountETH = (parseFloat(value) / price).toFixed(2);
    formik.setFieldValue('amountETH', amountETH);
  };

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="top"
      justifyContent="space-between"
      sx={{ marginTop: 2 }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={10}>
            <Item>
              Balance: {'Ξ '} {etherBalance}
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <Link onClick={setMaxAmount} href="#">
                MAX
              </Link>
            </Item>
          </Grid>
        </Grid>
        <TextField
          fullWidth
          autoComplete=""
          type="text"
          {...getFieldProps('amountETH')}
          InputProps={{
            // startAdornment: <InputAdornment position="start">ETH</InputAdornment>,
            endAdornment: (
              <InputAdornment position="end">
                {chaindata ? chaindata.nativeCurrency : ''}
              </InputAdornment>
            ),
          }}
          onChange={onChangeAmountETH}
          error={Boolean(touched.amountETH && errors.amountETH)}
          helperText={touched.amountETH && errors.amountETH}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <Item>
              Balance: {'$ '} {fiatBalance}
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <Link onClick={setMaxAmount} href="#">
                MAX
              </Link>
            </Item>
          </Grid>
        </Grid>
        <TextField
          fullWidth
          autoComplete=""
          type="text"
          {...getFieldProps('amountUSD')}
          InputProps={{
            // startAdornment: <InputAdornment position="start">ETH</InputAdornment>,
            endAdornment: <InputAdornment position="end"> $</InputAdornment>,
          }}
          onChange={onChangeAmountUSD}
          error={Boolean(touched.amountUSD && errors.amountUSD)}
          helperText={touched.amountUSD && errors.amountUSD}
        />
      </Box>
    </Stack>
  );
}
