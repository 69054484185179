/* eslint-disable import/no-duplicates */
import {
  createContext,
  ReactNode,
  useEffect,
  useReducer,
  useState,
} from 'react';
import firebase, { initializeApp } from 'firebase/app';

import {
  getDoc,
  getDocs,
  setDoc,
  doc,
  getFirestore,
  DocumentData,
  collection,
} from '@firebase/firestore';

import {
  ref,
  getStorage,
  getDownloadURL,
  uploadBytesResumable,
} from 'firebase/storage';

import {
  Auth,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
} from '@firebase/auth';
// @types
import {
  ActionMap,
  AuthState,
  AuthUser,
  FirebaseContextType,
} from '@blockchainfirst/types';
//
import { firebaseConfig } from '@blockchainfirst/config';
import { User } from '@blockchainfirst/types';

// ----------------------------------------------------------------------

export const ADMIN_EMAILS = ['don@gmail.com', 'schaar.juergen@gmail.com'];
//if (!firebase.getApps().length) {
initializeApp(firebaseConfig);
//firebase.firestore();
export const app = initializeApp(firebaseConfig);
export const database = getFirestore(app);
export const auth: Auth = getAuth();
export const storage = getStorage(app);

//}

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

enum Types {
  Initial = 'INITIALISE',
}

type FirebaseAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
};

type FirebaseActions =
  ActionMap<FirebaseAuthPayload>[keyof ActionMap<FirebaseAuthPayload>];

const reducer = (state: AuthState, action: FirebaseActions) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext<FirebaseContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [profile, setProfile] = useState<DocumentData | undefined>();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [progress, setProgress] = useState(0);
  useEffect(
    () =>
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          //console.log('onAuthStateChanged: ', auth.currentUser?.uid);

          const userData = await getDoc<DocumentData>(
            doc(database, 'users', user?.uid)
          );
          if (userData) {
            //console.log('userData: ', userData.data());
            setProfile(userData.data());
            //console.log('profile: ', profile);
          }
          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: true, user },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    [dispatch]
  );

  const login = (email: string, password: string) =>
    signInWithEmailAndPassword(auth, email, password);

  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const loginWithFaceBook = () => {
    const provider = new FacebookAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const loginWithTwitter = () => {
    const provider = new TwitterAuthProvider();
    return signInWithPopup(auth, provider);
  };

  const register = (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) =>
    createUserWithEmailAndPassword(auth, email, password).then((res) => {
      setDoc(doc(database, 'users', res.user?.uid), {
        uid: res.user?.uid,
        email,
        displayName: `${firstName} ${lastName}`,
        role: 'user',
      });
    });

  const logout = async () => {
    console.log('AuthProvider logout');
    await auth.signOut();
  };

  const resetPassword = async (email: string) => {
    await sendPasswordResetEmail(auth, email);
  };

  const updateProfile = async (user: User) => {
    console.log('updateProfile user: ', user);
    //if (user.photoURL){ const storageRef = await ref(storage, user.photoURL.file)};
    if (user.photoURL && user.photoURL.file) {
      console.log('photoURL: ', user.photoURL.file);
      const sotrageRef = ref(storage, `files/${user.photoURL.file.name}`);
      const uploadTask = uploadBytesResumable(sotrageRef, user.photoURL.file);
      console.log('sotrageRef: ', sotrageRef);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(prog);
        },
        (error) => console.log(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            console.log('File available at', downloadURL);
            user.photoURL = downloadURL;
            const uid = auth.currentUser?.uid as string | null;
            if (uid) {
              await setDoc(doc(database, 'users', uid), user);
              setProfile(user);
            }
          });
        }
      );
    } else {
      const uid = auth.currentUser?.uid as string | null;
      if (uid) {
        await setDoc(doc(database, 'users', uid), user);
        setProfile(user);
      }
    }
  };

  const authenticated = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: authenticated.uid,
          email: authenticated.email,
          photoURL: authenticated.photoURL || profile?.photoURL,
          displayName: authenticated.displayName || profile?.displayName,
          //role: ADMIN_EMAILS.includes(authenticated.email) ? 'admin' : 'user',
          role: profile?.role || 'user',
          phoneNumber: authenticated.phoneNumber || profile?.phoneNumber || '',
          country: profile?.country || '',
          address: profile?.address || '',
          state: profile?.state || '',
          city: profile?.city || '',
          zipCode: profile?.zipCode || '',
          about: profile?.about || '',
          isPublic: profile?.isPublic || false,
        },
        login,
        register,
        loginWithGoogle,
        loginWithFaceBook,
        loginWithTwitter,
        logout,
        resetPassword,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
