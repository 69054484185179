import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import fileFill from '@iconify/icons-eva/file-fill';
// routes
import { PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    path: '/',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
  },
  { title: 'About us', path: PATH_PAGE.about },
  { title: 'Contact us', path: PATH_PAGE.contact },
  { title: 'FAQs', path: PATH_PAGE.faqs },
  // {
  //   title: 'Dashboard',
  //   path: PATH_DASHBOARD.root + '/app',
  //   icon: <Icon icon={fileFill} {...ICON_SIZE} />,
  // },
];

export default menuConfig;
