// ----------------------------------------------------------------------

export const role = [
  'UX Designer',
  'Full Stack Designer',
  'Backend Developer',
  'UX Designer',
  'UX Designer',
  'Project Manager',
  'Leader',
  'Backend Developer',
  'Project Manager',
  'Software Engineer, passionate Pilot and Scuba Diver',
  'UI/UX Designer',
  'UI/UX Designer',
  'Software Engineer, passionate Pilot and Scuba Diver',
  'Backend Developer',
  'Backend Developer',
  'Front End Developer',
  'Backend Developer',
  'Full Stack Designer',
  'Full Stack Developer',
  'Backend Developer',
  'UX Designer',
  'Software Engineer, passionate Pilot and Scuba Diver',
  'Project Manager',
  'UI/UX Designer',
  'Software Engineer, passionate Pilot and Scuba Diver',
  'Project Manager',
  'Full Stack Developer',
  'Hr Manager',
  'Hr Manager',
  'UI/UX Designer',
  'Project Manager',
  'Full Stack Designer',
  'Software Engineer, passionate Pilot and Scuba Diver',
  'Leader',
  'Front End Developer',
  'UI/UX Designer',
  'Project Manager',
  'UI/UX Designer',
  'Software Engineer, passionate Pilot and Scuba Diver',
  'Full Stack Designer',
];
