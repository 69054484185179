export * from './lib/animate/index';
export { default as Settings } from './lib/settings';
export { default as RtlLayout } from './lib/RtlLayout';
export { default as ScrollToTop } from './lib/ScrollToTop';
//export { default as LoadingScreen } from './lib/LoadingScreen'
export { default as ThemePrimaryColor } from './lib/ThemePrimaryColor';
export { default as ColorManyPicker } from './lib/ColorManyPicker';
export { default as ColorPreview } from './lib/ColorPreview';
export { default as NotistackProvider } from './lib/NotistackProvider';
export { ProgressBarStyle } from './lib/LoadingScreen';
export { LoadingScreen } from './lib/LoadingScreen';
export { default as Page } from './lib/Page';
export { default as Logo } from './lib/Logo';
export { default as MyAvatar } from './lib/MyAvatar';
export { default as NavSection } from './lib/NavSection';
export { default as Scrollbar } from './lib/Scrollbar';
export { default as Label } from './lib/Label';
export { default as MenuPopover } from './lib/MenuPopover';
export { default as CustomizedSnackbar } from './lib/CustomizedSnackbar';
export { default as Spinner } from './lib/Spinner';
export { default as SvgIconStyle } from './lib/SvgIconStyle';
export { default as AlertDialog } from './lib/AlertDialog';
export { default as QrCodeDialog } from './lib/QRCodeDialog';
export { default as ConfirmDialog } from './lib/ConfirmDialog';
export { default as HeaderBreadcrumbs } from './lib/HeaderBreadcrumbs';
export { default as SearchNotFound } from './lib/SearchNotFound';

export * from './lib/_external-pages/landing';

export { default as AccountChip } from './lib/wallet/AccountChip';
export { default as Wallet } from './lib/wallet/Wallet';
export { default as AddressFormField } from './lib/wallet/AddressFormField';
export { default as AmountsFormFields } from './lib/wallet/AmountsFormFields';
export { default as BalanceFormField } from './lib/wallet/BalanceFormField';
export * from './lib/helperformik/helperFormik';
export * from './lib/getFileFormat/getFileFormat';
export * from './lib/@material-extend';

export * from './lib/_dashboard/user/account';
export * from './lib/_dashboard/user/cards';
export * from './lib/_dashboard/user/profile';
export * from './lib/MyAvatar';

// export * from './lib/_dashboard/documents/DocumentNewForm';

export * from './lib/upload';
export { default as DocumentNewForm } from './lib/_dashboard/documents/DocumentNewForm';
export { default as DocumentsTable } from './lib/_dashboard/documents/DocumentsTable';

export * from './lib/carousel';
export * from './lib/map';
export * from './lib/react-multi-dialog/MultiDialog';
export * from './lib/@material-extend';
