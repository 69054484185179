// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import { SvgIconStyle } from '@blockchainfirst/ui-shared/components';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: '100%', height: '100%' }}
  />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  exam: getIcon('ic_dashboard'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'Gyrokopter Exam',
        path: PATH_DASHBOARD.general.pageExamStart,
        icon: ICONS.exam,
      },
      {
        title: 'Documents',
        path: PATH_DASHBOARD.general.pageDocuments,
        icon: ICONS.exam,
      },
      // {
      //   title: 'Wallet',
      //   path: PATH_DASHBOARD.general.pageWallet,
      //   icon: ICONS.analytics,
      // },
      // {
      //   title: 'Profile',
      //   path: PATH_DASHBOARD.general.pageProfile,
      //   icon: ICONS.dashboard,
      // },
      // {
      //   title: 'NFTs',
      //   path: PATH_DASHBOARD.general.pageNFTs,
      //   icon: ICONS.ecommerce,
      // },
      // {
      //   title: 'PDF',
      //   path: PATH_DASHBOARD.general.pagePDF,
      //   icon: ICONS.ecommerce,
      // },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // {
      //   title: 'Categories',
      //   path: PATH_DASHBOARD.app.categories,
      //   icon: ICONS.user,
      // },
      {
        title: 'Questions',
        path: PATH_DASHBOARD.app.questions,
        icon: ICONS.user,
      },
      {
        title: 'Documents',
        path: PATH_DASHBOARD.app.documents,
        icon: ICONS.user,
      },
      // {
      //   title: 'User',
      //   path: PATH_DASHBOARD.app.addexamquesion,
      //   icon: ICONS.user,
      // },
    ],
  },
];

export default sidebarConfig;
