import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import {
  useRecords,
  getRecord,
  getRecords,
} from '@blockchainfirst/utils/database';
import { database } from '@blockchainfirst/ui-shared/contexts';

import {
  onSnapshot,
  collection,
  addDoc,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
  doc,
  orderBy,
  getFirestore,
} from 'firebase/firestore';

// utils

import {
  QuestionState,
  QuestionCategory,
  QuestionType,
  QuestionDifficulty,
} from '../../types/types';

export type ExamStateType = {
  isLoading: boolean;
  error: boolean;
  index: number;
  score: number;
  questions: QuestionState[];
  category: QuestionCategory;
  difficulty: QuestionDifficulty;
  type: QuestionType;
  amount_of_questions: number;
};

const initialState: ExamStateType = {
  isLoading: false,
  error: false,
  index: 0,
  score: 0,
  questions: [],
  category: QuestionCategory.ALL,
  difficulty: QuestionDifficulty.ALL,
  type: QuestionType.ALL,
  amount_of_questions: 50,
};

const slice = createSlice({
  name: 'gyrocopterexam',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setScore(state) {
      console.log('setScore: ', state.score);
      state.score = state.score + 1;
    },
    resetScore(state) {
      console.log('resetScore: ', state.score);
      state.score = 0;
    },
    increaseIndex(state) {
      state.index =
        state.index < state.questions.length ? state.index + 1 : state.index;
    },
    decreaseIndex(state) {
      state.index = state.index > 0 ? state.index - 1 : state.index;
    },

    setIndex(state, action) {
      state.index = action.payload;
    },
    setCategory(state, action) {
      state.category = action.payload;
    },
    setDifficulty(state, action) {
      state.difficulty = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    // GET Questions
    getQuestions(state, action) {
      state.isLoading = false;
      state.questions = action.payload;
    },
    // DELETE QUESTION
    deleteQuestion(state, action) {
      // const _deleteQuestion = filter(state.questions, (question) => question. !== action.payload);
      // state.questions = _deleteQuestion;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteQuestion } = slice.actions;

export function startNew() {
  try {
    console.log('REDUX start');
    dispatch(slice.actions.getQuestions([]));
    dispatch(slice.actions.setIndex(0));
    dispatch(slice.actions.resetScore());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export function replayQuiz() {
  try {
    console.log('REDUX replayQuiz');
    //dispatch(slice.actions.getQuestions([]));
    dispatch(slice.actions.setIndex(0));
    dispatch(slice.actions.resetScore());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export function setCategory(category: string) {
  try {
    dispatch(slice.actions.setCategory(category));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export function setDifficulty(difficulty: string) {
  try {
    dispatch(slice.actions.setDifficulty(difficulty));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export function setType(type: string) {
  try {
    dispatch(slice.actions.setType(type));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export function increaseQuestionIndex() {
  try {
    dispatch(slice.actions.increaseIndex());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export function decreaseQuestionIndex() {
  try {
    dispatch(slice.actions.decreaseIndex());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export function increaseScore() {
  try {
    dispatch(slice.actions.setScore());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export function getQuestions(
  category: string,
  difficulty: string,
  type: string
) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const COLLECTION = 'exam-questions';
      let questions = (await getRecords(
        COLLECTION,
        category,
        difficulty,
        type
      )) as QuestionState[];
      if (difficulty !== 'all') {
        questions = questions.filter(
          (question) => question.difficulty === difficulty
        );
      }
      if (type !== 'all') {
        questions = questions.filter((question) => question.type === type);
      }
      //console.log('REDUX record: ', questions);
      dispatch(slice.actions.getQuestions(questions));
      //dispatch(slice.actions.setIndex(0));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getCategories() {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       dispatch(slice.actions.setCategories(categoryies));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
