// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

const ROOTS_AUTH = '/auth';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

// ----------------------------------------------------------------------
export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    pageExamStart: path(ROOTS_DASHBOARD, '/examstart'),
    pageDocuments: path(ROOTS_DASHBOARD, '/documents'),
    pageWallet: path(ROOTS_DASHBOARD, '/wallet'),
    pageProfile: path(ROOTS_DASHBOARD, '/profile'),
    pageNFTs: path(ROOTS_DASHBOARD, '/nfts'),
    pagePDF: path(ROOTS_DASHBOARD, '/pdf'),
    pageBuyCrypto: path(ROOTS_DASHBOARD, '/buycrypto'),
    pageSendCrypto: path(ROOTS_DASHBOARD, '/sendcrypto'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  app: {
    root: path(ROOTS_DASHBOARD, '/app'),
    categories: path(ROOTS_DASHBOARD, '/app/categories'),
    addexamquesion: path(ROOTS_DASHBOARD, '/app/addexamquestion'),
    documents: path(ROOTS_DASHBOARD, '/app/documents'),
    questions: path(ROOTS_DASHBOARD, '/app/questions'),
    test: path(ROOTS_DASHBOARD, '/app/test'),
    pageFour: path(ROOTS_DASHBOARD, '/app/four'),
    pageFive: path(ROOTS_DASHBOARD, '/app/five'),
    pageSix: path(ROOTS_DASHBOARD, '/app/six'),
  },
};
