import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
//import Controls from "./controls/Controls";
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';

const useStyles = makeStyles((theme: any) => ({
  dialog: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: 'center',
  },
  dialogContent: {
    textAlign: 'center',
  },
  dialogAction: {
    justifyContent: 'center',
  },
  titleIcon: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      cursor: 'default',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '3rem',
    },
  },
}));

export enum ConfirmDialogResult {
  YES = 'yes',
  NO = 'no',
}

export interface DialogProps {
  id: string;
  children?: React.ReactNode;
  title: string;
  content: string;
  open: boolean;
  defaultOpen?: boolean;
  onClose: (res: ConfirmDialogResult) => void;
}

const ConfirmDialog = forwardRef((props: DialogProps, ref: Ref<any>) => {
  //export function ConfirmDialog(props: any) {
  //const { confirmDialog, setConfirmDialog } = props;
  const classes = useStyles();
  const {
    children,
    title,
    content,
    open,
    defaultOpen = false,
    onClose,
    ...other
  } = props;
  const [isopen, setOpen] = useState(open);

  useImperativeHandle(
    ref,
    () => ({
      OpenDialog() {
        setOpen(true);
      },
    }),
    []
  );

  useEffect(() => {
    if (open) setOpen(true);
    if (defaultOpen) setOpen(true);
  }, []);

  const handleClose = (res: ConfirmDialogResult) => {
    onClose(res);
    setOpen(false);
  };

  return (
    <Dialog open={isopen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disableRipple className={classes.titleIcon}>
          <NotListedLocationIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle2">{content}</Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            handleClose(ConfirmDialogResult.NO);
          }}
        >
          No
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            handleClose(ConfirmDialogResult.YES);
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default ConfirmDialog;
