import type { AddEthereumChainParameter } from '@web3-react/types';
import { InfuraId, AlchemyId } from '@blockchainfirst/config';

const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
};

const MATIC: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Matic',
  symbol: 'MATIC',
  decimals: 18,
};

interface BasicChainInformation {
  urls: string[] | undefined[];
  name: string;
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency'];
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls'];
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency;
}

export function getAddChainParameters(
  chainId: number
): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId];
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls as string[],
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    };
  } else {
    return chainId;
  }
}

export const CHAINS: {
  [chainId: number]: BasicChainInformation | ExtendedChainInformation;
} = {
  1: {
    urls: [
      `https://mainnet.infura.io/v3/${InfuraId}`,
      `https://eth-mainnet.alchemyapi.io/v2/${AlchemyId}`,
      // process.env.infuraKey
      //   ? `https://mainnet.infura.io/v3/${process.env.infuraKey}`
      //   : undefined,
      // process.env.alchemyKey
      //   ? `https://eth-mainnet.alchemyapi.io/v2/${process.env.alchemyKey}`
      //   : undefined,
    ].filter((url) => url !== undefined),
    name: 'Mainnet',
    nativeCurrency: ETH,
  },
  3: {
    urls: [
      `https://ropsten.infura.io/v3/${InfuraId}`,
      // process.env.infuraKey
      //   ? `https://ropsten.infura.io/v3/${process.env.infuraKey}`
      //   : undefined,
    ].filter((url) => url !== undefined),
    name: 'Ropsten',
    nativeCurrency: ETH,
  },
  4: {
    urls: [
      `https://rinkeby.infura.io/v3/${InfuraId}`,
      // process.env.infuraKey
      //   ? `https://rinkeby.infura.io/v3/${process.env.infuraKey}`
      //   : undefined,
    ].filter((url) => url !== undefined),
    name: 'Rinkeby',
    nativeCurrency: ETH,
  },
  5: {
    urls: [
      `https://goerli.infura.io/v3/${InfuraId}`,
      // process.env.infuraKey
      //   ? `https://goerli.infura.io/v3/${process.env.infuraKey}`
      //   : undefined,
    ].filter((url) => url !== undefined),
    name: 'Görli',
    nativeCurrency: ETH,
  },
  42: {
    urls: [
      `https://kovan.infura.io/v3/${InfuraId}`,
      // process.env.infuraKey
      //   ? `https://kovan.infura.io/v3/${process.env.infuraKey}`
      //   : undefined,
    ].filter((url) => url !== undefined),
    name: 'Kovan',
    nativeCurrency: ETH,
  },
  // Optimism
  10: {
    urls: [
      `https://optimism-mainnet.infura.io/v3/${InfuraId}`,
      // process.env.infuraKey
      //   ? `https://optimism-mainnet.infura.io/v3/${process.env.infuraKey}`
      //   : undefined,
    ].filter((url) => url !== undefined),
    name: 'Optimistic Ethereum',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://optimistic.etherscan.io'],
  },
  69: {
    urls: [
      `https://optimism-kovan.infura.io/v3/${InfuraId}`,
      // process.env.infuraKey
      //   ? `https://optimism-kovan.infura.io/v3/${process.env.infuraKey}`
      //   : undefined,
    ].filter((url) => url !== undefined),
    name: 'Optimistic Kovan',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://kovan-optimistic.etherscan.io'],
  },
  // Arbitrum
  42161: {
    urls: [
      `https://arbitrum-mainnet.infura.io/v3/${InfuraId}`,
      // process.env.infuraKey
      //   ? `https://arbitrum-mainnet.infura.io/v3/${process.env.infuraKey}`
      //   : undefined,
    ].filter((url) => url !== undefined),
    name: 'Arbitrum One',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://arbiscan.io'],
  },
  421611: {
    urls: [
      `https://arbitrum-rinkeby.infura.io/v3/${InfuraId}`,
      // process.env.infuraKey
      //   ? `https://arbitrum-rinkeby.infura.io/v3/${process.env.infuraKey}`
      //   : undefined,
    ].filter((url) => url !== undefined),
    name: 'Arbitrum Testnet',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://testnet.arbiscan.io'],
  },
  // Polygon
  137: {
    urls: [
      `https://polygon-mainnet.infura.io/v3/${InfuraId}`,
      // process.env.infuraKey
      //   ? `https://polygon-mainnet.infura.io/v3/${process.env.infuraKey}`
      //   : undefined,
      'https://polygon-rpc.com',
    ].filter((url) => url !== undefined),
    name: 'Polygon Mainnet',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://polygonscan.com'],
  },
  80001: {
    urls: [
      `https://polygon-mumbai.infura.io/v3/${InfuraId}`,
      // process.env.infuraKey
      //   ? `https://polygon-mumbai.infura.io/v3/${process.env.infuraKey}`
      //   : undefined,
    ].filter((url) => url !== undefined),
    name: 'Polygon Mumbai',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  },
};

export const URLS: { [chainId: number]: string[] | undefined[] } = Object.keys(
  CHAINS
).reduce<{ [chainId: number]: string[] | undefined[] }>(
  (accumulator, chainId) => {
    const validURLs: string[] | undefined[] = CHAINS[Number(chainId)].urls;
    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs;
    }
    return accumulator;
  },
  {}
);
