// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material';

//
import {
  varFadeInUp,
  MotionInView,
  varFadeInDown,
} from '@blockchainfirst/ui-shared/components';
import { motion } from 'framer-motion';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(5),
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(5),
  },
}));

const WindyStyle = styled(motion.div)(({ theme }) => ({
  zIndex: 9,
  width: '100%',
  height: '100%',
  position: 'relative',
}));

// ----------------------------------------------------------------------

export default function Windy() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <RootStyle>
      <Container maxWidth="lg">
        <Box sx={{ mb: 5, textAlign: 'center' }}>
          <MotionInView variants={varFadeInUp}>
            <Typography
              component="p"
              variant="overline"
              sx={{ mb: 2, color: 'text.secondary' }}
            >
              Weather Conditions
            </Typography>
          </MotionInView>
          {/* <MotionInView variants={varFadeInDown}>
            <Typography variant="h2" sx={{ mb: 3 }}>
              The right Tour for your Budget and Schedule
            </Typography>
          </MotionInView> */}
          <MotionInView variants={varFadeInDown}>
            <Typography
              sx={{
                color: isLight ? 'text.secondary' : 'text.primary',
              }}
            >
              Depending on the current weather conditions, we might not be able
              to fly. Please call or send us a message wheather it's possible
              now or when.
            </Typography>
          </MotionInView>
        </Box>
        <WindyStyle>
          <iframe
            src="https://embed.windy.com/"
            width={1150}
            height={600}
            //sandbox="allow-scripts allow-modal"
            loading="eager"
          ></iframe>
        </WindyStyle>
        {/* </Box> */}
      </Container>
    </RootStyle>
  );
}
