import { useState, useEffect } from 'react';
import {
  onSnapshot,
  collection,
  addDoc,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
  doc,
  orderBy,
  getFirestore,
  QueryConstraint,
  Query,
} from 'firebase/firestore';

import {
  ref,
  getStorage,
  getDownloadURL,
  uploadBytesResumable,
} from 'firebase/storage';

//export const db = getFirestore();
//export const storage = getStorage();

import { storage, database as db } from '@blockchainfirst/ui-shared/contexts';

export const addRecord = async (payload: any, collectionName: string) => {
  console.log('addRecord');
  const collectionRef = collection(db, collectionName);
  const docRef = await addDoc(collectionRef, {
    ...payload,
    created_at: serverTimestamp(),
  });
  console.log('The new ID is: ' + docRef.id);
  return docRef.id;
};

export const updateRecord = async (
  id: string,
  payload: any,
  collectionName: string
) => {
  console.log('updateRecord: ', id);
  const docRef = doc(db, collectionName, id);
  await updateDoc(docRef, { ...payload, updated_at: serverTimestamp() });
};

export const deleteRecord = async (id: string, collectionName: string) => {
  console.log('deleteRecord: ', id);
  const docRef = doc(db, collectionName, id);
  await deleteDoc(docRef);
};

export const getRecord = async (id: string, collectionName: string) => {
  console.log('getRecord: ', id);
  const docRef = doc(db, collectionName, id);
  return (await getDoc(docRef)).data();
};

export const getRecords = async (
  collectionName: string,
  category: string,
  difficulty: string,
  type: string
) => {
  const collectionRef = collection(db, collectionName);
  console.log('firgetRecords category: ', category);
  console.log('firgetRecords category: ', difficulty);
  console.log('firgetRecords category: ', type);
  let q: Query;

  const whereCategory =
    category === 'all'
      ? where('category', 'in', [
          'aeronautic',
          'components_of_ultralight',
          'technicals',
          'meteorology',
          'regulations',
          'general_rules_of_the_air',
          'definitions',
        ])
      : where('category', '==', category);

  const whereDifficulty =
    difficulty === 'all'
      ? where('difficulty', 'in', ['easy', 'medium', 'hard'])
      : where('difficulty', '==', difficulty);

  const whereType =
    type === 'all'
      ? where('type', 'in', ['multiple', 'boolean'])
      : where('type', '==', type);

  q = query(collectionRef, whereCategory);

  // const stateQuery = query(citiesRef, where('state', '==', 'CA'));
  // const populationQuery = query(citiesRef, where('population', '<', 100000));
  // const nameQuery = query(citiesRef, where('name', '>=', 'San Francisco'));

  const snapshot = await getDocs(q);

  return snapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
};

export const handleQueryDelete = async () => {
  const userInputName = prompt('Enter color name');

  const collectionRef = collection(db, 'colors');
  const q = query(collectionRef, where('name', '==', userInputName));
  const snapshot = await getDocs(q);

  const results = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

  results.forEach(async (result) => {
    const docRef = doc(db, 'colors', result.id);
    await deleteDoc(docRef);
  });
};

// Custom Hook
export function useRecords(collectionName: string) {
  const [records, setRecords] = useState([]) as any[];

  useEffect(
    () =>
      onSnapshot(collection(db, collectionName), (snapshot) =>
        setRecords(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      ),
    []
  );

  return records;
}
