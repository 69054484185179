// hooks
//import { useAuth } from '@blockchainfirst/hooks';
//
import { MAvatarProps } from './@material-extend';
import MAvatar from './@material-extend/MAvatar';
//import { MAvatarProps } from './@material-extend/MAvatar';
import { createAvatar } from '@blockchainfirst/utils/functions';
import { AvatarProps } from '@mui/material';

// ----------------------------------------------------------------------

// export interface MAvatarProps extends AvatarProps {
//   color?: string;
// }

export default function MyAvatar({ ...other }: MAvatarProps) {
  //const { user } = useAuth();

  const user: any = {};

  return (
    <MAvatar
      src={user?.photoURL}
      alt={user?.displayName}
      color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </MAvatar>
  );
}
