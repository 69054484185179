import { useSettings } from '@blockchainfirst/hooks';
// components
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';

import {
  Table,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Box,
} from '@mui/material';

import { useState, useEffect, useCallback, useMemo } from 'react';

import {
  getDoc,
  getDocs,
  setDoc,
  doc,
  getFirestore,
  DocumentData,
  collection,
} from '@firebase/firestore';
import { getStorage, ref, deleteObject } from 'firebase/storage';

import {
  addRecord,
  updateRecord,
  deleteRecord,
  useRecords,
  getRecord,
} from '@blockchainfirst/utils/database';
import { storage } from '@blockchainfirst/ui-shared/contexts';

// @types
import { DocumentManager } from '@blockchainfirst/types';
import { filter } from 'lodash';

import ListHead from './ListHead';
import MoreMenu from './MoreMenu';
import ListToolbar from './ListToolbar';

import {
  getFileType,
  getFileName,
  getFileFullName,
  getFileFormat,
  getFileThumb,
} from '@blockchainfirst/utils/functions';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
//import { useAuth } from '@blockchainfirst/hooks';

import { namedQuery } from '@firebase/firestore';

type Anonymous = Record<string | number, string>;

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function applySortFilter(
  array: DocumentManager[],
  comparator: (a: any, b: any) => number,
  query: string
) {
  const stabilizedThis = array.map((el, index) => [el, index] as const);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (record) => record.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order: string, orderBy: string) {
  return order === 'desc'
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

type Props = {
  user: any;
};

export default function DocumentsTable({ user }: Props) {
  const [recordId, setRecordId] = useState<string>('');
  const COLLECTION = 'documents';
  const documents = useRecords(COLLECTION) as DocumentManager[];
  console.log('documents: ', documents);
  //const { user } = useAuth();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const TABLE_HEAD = [
    {
      id: 'filetype',
      label: 'Type',
      avatar: '',
      showAvatar: false,
      alignRight: false,
      align: 'left',
      role: 'user',
    },
    {
      id: 'filename',
      label: 'Filename',
      avatar: '',
      showAvatar: false,
      alignRight: false,
      align: 'left',
      role: 'user',
    },
    {
      id: 'download',
      label: 'Download',
      showAvatar: false,
      alignRight: true,
      align: 'center',
      role: 'user',
    },
    {
      id: 'action',
      label: 'Action',
      showAvatar: false,
      alignRight: true,
      align: 'right',
      role: 'admin',
    },
  ];

  const handleDelete = async (id: string) => {
    console.log('handleDelete: ', id);
    //await deleteRecord(id, COLLECTION);
    const record = await getRecord(id, COLLECTION);
    console.log('record: ', record);
    if (record && record.url) {
      const sotrageRef = ref(storage, `documents/${record.filename}`);
      console.log('sotrageRef: ', sotrageRef);
      deleteObject(sotrageRef)
        .then(async () => {
          // File deleted successfully
          await deleteRecord(id, COLLECTION);
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });
    }
  };

  const handleEdit = async (id: string) => {
    console.log('handleEdit: ', id);
    //const record = (await getRecord(id, COLLECTION));
  };

  // Table Functions -------------------------------------------
  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelecteds = documents.map((n: any) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - documents.length) : 0;

  const filteredRecords = applySortFilter(
    documents,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = filteredRecords.length === 0;

  const handleFilterByName = (filterName: string) => {
    setFilterName(filterName);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Table Functions -------------------------------------------
  return (
    <>
      <ListToolbar
        numSelected={selected.length}
        filterName={filterName}
        onFilterName={handleFilterByName}
      />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={documents.length}
              numSelected={selected.length}
              role={'admin'}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {filteredRecords
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any) => {
                  const { id, filetype, name, url } = row;
                  const isItemSelected = selected.indexOf(name) !== -1;

                  return (
                    <TableRow
                      hover
                      key={row.id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      {user && user.role === 'admin' ? (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onClick={() => handleClick(name)}
                          />
                        </TableCell>
                      ) : null}
                      <TableCell align="left">
                        {getFileThumb(filetype)}
                      </TableCell>
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="center">
                        <Box component="a" href={url}>
                          <FileDownloadIcon />
                        </Box>
                      </TableCell>
                      {user && user.role === 'admin' ? (
                        <TableCell align="right">
                          <MoreMenu
                            onDelete={() => handleDelete(id)}
                            onEdit={() => handleEdit(id)}
                            name={name}
                          />
                        </TableCell>
                      ) : null}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[15, 25, 35]}
        component="div"
        count={documents.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, page) => setPage(page)}
        onRowsPerPageChange={(e) => handleChangeRowsPerPage}
      />
    </>
  );
}
