import { motion } from 'framer-motion';
// material
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, Grid } from '@mui/material';
//
import {
  varFadeIn,
  varWrapEnter,
  varFadeInRight,
  TextAnimate,
} from '@blockchainfirst/ui-shared/components';

// ----------------------------------------------------------------------

const CONTACTS = [
  {
    country: 'Tamarindo',
    address:
      'Aeroclub Tico Wings Tamarindo, Carretera a Playa Grande 700m, Provincia de Guanacaste, Tamarindo, 50309, Costa Rica',
    phoneNumber: '(+506) 8464-6010',
    mapslink:
      'https://www.google.com/maps/place/Fly+with+Us+-+Tamarindo/@10.3358573,-85.803572,17z/data=!3m1!4b1!4m5!3m4!1s0x8f9e397afed48e4b:0xe49261c9151c2ad4!8m2!3d10.3358573!4d-85.8013833',
  },
  {
    country: 'Aranjuez',
    address: '508 Bridle Avenue Newnan, GA 30263',
    phoneNumber: '(+506) 8464-6010',
    mapslink:
      'https://www.google.com/maps/place/R%C3%ADo+Aranjuez+cerca+de+Pitahaya/@10.0187493,-84.8191743,16z/data=!4m12!1m6!3m5!1s0x8f9e397afed48e4b:0xe49261c9151c2ad4!2sFly+with+Us+-+Tamarindo!8m2!3d10.3358573!4d-85.8013833!3m4!1s0x8fa02f71a1fed2cb:0x85f009b45a7e655!8m2!3d10.0277796!4d-84.8264071',
  },
  {
    country: 'Samara',
    address: '508 Bridle Avenue Newnan, GA 30263',
    phoneNumber: '(+506) 8464-6010',
    mapslink:
      'https://www.google.com/maps/place/MRMR+-+La+Pista/@9.899966,-85.5548444,16z/data=!4m9!1m2!2m1!1sGuanacaste+Province,+S%C3%A1mara+airport!3m5!1s0x8f9faa434fcec0cb:0x16eb483a0afdbfc8!8m2!3d9.899966!4d-85.550467!15sCiRHdWFuYWNhc3RlIFByb3ZpbmNlLCBTw6FtYXJhIGFpcnBvcnSSAQhhaXJzdHJpcA',
  },
];

const RootStyle = styled(motion.div)(({ theme }) => ({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: 'url(/static/overlay.svg), url(/static/contact/hero.jpg)',
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    height: 560,
    padding: 0,
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    position: 'absolute',
    bottom: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------

export default function ContactHero() {
  return (
    <RootStyle initial="initial" animate="animate" variants={varWrapEnter}>
      <Container maxWidth="lg" sx={{ position: 'relative', height: '100%' }}>
        <ContentStyle>
          <TextAnimate
            text="Where"
            sx={{ color: 'primary.main' }}
            variants={varFadeInRight}
          />
          <br />
          <Box sx={{ display: 'inline-flex', color: 'common.white' }}>
            <TextAnimate text="to" sx={{ mr: 2 }} />
            <TextAnimate text="find" sx={{ mr: 2 }} />
            <TextAnimate text="us?" />
          </Box>

          <Grid container spacing={5} sx={{ mt: 5, color: 'common.white' }}>
            {CONTACTS.map((contact) => (
              <Grid
                key={contact.country}
                item
                xs={12}
                sm={6}
                md={3}
                lg={2}
                sx={{ pr: { md: 5 } }}
              >
                <motion.div variants={varFadeIn}>
                  <Typography variant="h6" paragraph>
                    {contact.country}
                  </Typography>
                </motion.div>
                <motion.div variants={varFadeInRight}>
                  <Typography variant="body2">
                    {contact.address}
                    <br /> {contact.phoneNumber}
                  </Typography>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
