export { default as axios } from './lib/axios';
//export * from './lib/jwt'
export { default as createAvatar } from './lib/createAvatar';
export { default as fakeRequest } from './lib/fakeRequest';
export * from './lib/formatNumber';
export * from './lib/formatTime';
export { default as getColorName } from './lib/getColorName';
export * from './lib/highlight';
export * from './lib/getFileFormat';
export * from './lib/jwt';
