import './_apis_';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
//import Reducer from './app/reducer';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store as newstore, persistor } from './app/redux/store';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// material
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// redux
//import { store, persistor } from '@blockchainfirst/redux';
// import { AuthProvider } from './contexts/AwsCognitoContext';
// import { AuthProvider } from './contexts/Auth0Context';
import { AuthProvider } from './app/contexts/FirebaseContext';
// contexts
import {
  SettingsProvider,
  CollapseDrawerProvider,
  //AuthProvider,
} from '@blockchainfirst/ui-shared/contexts';
import App from './app/app';
import { LoadingScreen } from '@blockchainfirst/ui-shared/components';

//const store = createStore(Reducer);
// mock api

ReactDOM.render(
  // <StrictMode>
  <HelmetProvider>
    <ReduxProvider store={newstore}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <SettingsProvider>
          <CollapseDrawerProvider>
            <BrowserRouter>
              {/* <Provider store={store}> */}
              <AuthProvider>
                <App />
              </AuthProvider>
              {/* </Provider> */}
            </BrowserRouter>
          </CollapseDrawerProvider>
        </SettingsProvider>
      </PersistGate>
    </ReduxProvider>
  </HelmetProvider>,
  // </StrictMode>,
  document.getElementById('root')
);
